.landing-mobile {
}
/*firstpage mobile */
.firstpage-mobile {
    padding: 26px;
    background-color: #1b1b1b;
    display: flex;
    flex-direction: column;
    height: 759px;
    overflow: hidden;
    align-items: center;
}

.firstpage-mobile h1 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    color: #ffffff;
    text-align: center;
    padding-right: 3rem;
    padding-left: 3rem;
}

.firstpage-mobile p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    color: #ffffff;
    text-align: center;
    padding-right: 1rem;
    padding-left: 1rem;
}

.firstpage-mobile span {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding-right: 1rem;
    padding-left: 1rem;
    color: #ffffff;
}

.firstpage-mobile__titleside {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.pixel_img_mobile {
    position: absolute;
    right: 4px;
    bottom: -14px;
    width: 73px;
}

/* firstpage mobile */


/* detail mobile */
.details-mobile {
    padding: 26px;
    background: #ffffff;
}

.details-mobile h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    text-align: center;
    color: #171516;
}

.details-mobile__textside {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.details-mobile__imgside {
    align-items: center;
    justify-content: center;
    display: flex;
}

.detail {
}

.detail h3 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
}

.detail p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
}

/* detail mobile */

/* extension mobile */

.extension-mobile {
    padding: 26px;
    background: #f2f2f2;
}

.extension-mobile h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    color: #171516;
}

.extension-mobile p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: justify;
    color: #000000;
}

.extension-mobile__textside {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* extension mobile */

/* pricing page */

.pricing-mobile {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing-mobile__text-container {
}

.pricing-mobile h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    color: #171516;
}

.pricing-mobile p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #000000;
}

/* princing page */


/* footer-mobile */
.footer-mobile {
    padding: 26px;
    background: #1b1b1b;
}

.footer-mobile p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
}

.contact-us {
    display: flex;
    flex-direction: column;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
}

.contact-us span {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.footer-mobile-container {
    margin-top: 4rem;
}

.footer-mobile-right-container {
    margin-top: 3rem;
    text-align: center;
}
/* footer-mobile */