.terms {
    padding-right: 12%;
    padding-left: 12%;
}

.terms__section {
    margin-top: 5rem;
}

.terms h1 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    color: #171516;
    margin-bottom: 7rem;
    margin-top: 7rem;
}
.terms p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
}
