.landing {
}

/* animation */
.typingtext {
    white-space: nowrap;
    overflow: hidden;
    font-family: "Source Code Pro", monospace;
    margin-bottom: 0.5rem;
}

.typingtext {
    animation: animated-text 4s steps(40, end) 1s 1 normal both, animated-cursor 600ms steps(35, end) infinite;
}

.typingtext__second {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    font-family: "Source Code Pro", monospace;
}

.typingtext__second {
    animation: animated-text-2 2s steps(19, end) 5s 1 normal both, animated-cursor 600ms steps(29, end) infinite;
}

.typingtext__third {
    animation: fadeInUpKey3 0.5s steps(1, end) 7s 1 normal both, fadeInUpKey2 0.5s steps(1, end) 8s 1 normal both;
    /* animated-cursor 100ms steps(29,end) infinite; */
}

.fade_in_up {
    animation: fadeInUpKey 1.5s steps(29, end) 9s 1 normal both, animated-cursor 600ms steps(29, end) infinite;
}

@keyframes animated-text {
    from {
        width: 0;
    }
    to {
        width: 650px;
    }
}

@keyframes animated-text-2 {
    from {
        width: 0;
    }
    to {
        width: 180px;
    }
}

@keyframes fadeInUpKey3 {
    from {
        color: grey;
        opacity: 0;
    }
    to {
        opacity: 1;
        color: grey;
    }
}

@keyframes fadeInUpKey {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInUpKey2 {
    from {
        color: grey;
    }
    to {
        color: white;
    }
}

/* animation */

/* repsonsive*/
@media screen and (max-width: 1350px) {
    .detailspage__textside {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
    .extensionpage__textside {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
}

@media screen and (max-width: 1500px) {
    .headbar {
        padding-right: 7%!important;
        padding-left: 7%!important;
        padding-top: 3rem;
        padding-bottom: 0!important;

    }
    .firstpage__titleside {
        width: 50%!important;
        padding-right: 7%!important;
        padding-left: 7%!important;
    }
    .firstpage__imgside {
        width: 50%!important;
    }
}
/* repsonsive*/

/* firstpage */
.firstpage {
    background-color: #1b1b1b;
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.firstpage h1 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    color: #ffffff;
}

.firstpage h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    margin-right: -8px;
    color: #ffffff;
}

.firstpage span {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: white;
}

.firstpage p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
}

.firstpage__titleside {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    width: 60%;
    padding-right: 12%;
    padding-left: 12%;
}

.firstpage__imgside {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40%;
}

.pixel_img {
    position: absolute;
    right: 40px;
    bottom: -35px;
}
/* firstpage */

/* details page */

.detailspage {
    height: 836px;
    display: flex;
    background: #ffffff;
}

.detailspage h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    color: #171516;
}

.detailspage__textside {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    padding-right: 12%;
    padding-left: 12%;
}

.detailspage__imgside {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40%;
}

.detail {
}

.detail h3 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: #000000;
}

.detail p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
}

/* details page */

/* extension page */

.extensionpage {
    height: 836px;
    display: flex;
    background: #f2f2f2;
}

.extensionpage h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    color: #171516;
}

.extensionpage p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: justify;
    color: #000000;
}

.extensionpage__imgside {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
}

.extensionpage__textside {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40%;
    padding-right: 12%;
    padding-left: 12%;
}

/* extension page */

/* app page */

.app-page {
    height: 836px;
    color: white;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.app-page__text-container {
    padding-right: 30%;
    padding-left: 30%;
}

.app-page h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    color: #171516;
}

.app-page p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #000000;
}

.app-page img {
    margin-top: 8rem;
}

/* app page */

/* pricing page */

.pricingpage {
    padding: 5rem;
    height: 836px;
    background: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricingpage__text-container {
    padding-right: 10%;
    padding-left: 10%;
}

.pricingpage h2 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    color: #171516;
}

.pricingpage p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #000000;
}

/* princing page */

/* footer */
.footer {
    height: 300px;
    background: #1b1b1b;
    padding: 6rem;
}

.footer p {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
}

.contact-us {
    display: flex;
    flex-direction: column;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
}

.footer-container {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
}

.footer-right-container {
    margin-top: 3rem;
    text-align: center;
}
/* footer */
