@media screen and (max-width: 930px) {
    .pricing-mobile {
        padding: 26px;
    }

    .pricing-card {
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 100%;
        margin-top: 3rem !important;
        height: 350px !important;
        justify-content: center !important;
        padding-bottom: 1.7rem!important;
    }

    .price-container {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .pricing-card h3 {
        margin-bottom: 0 !important;
    }

    .pricing-mobile p {
        margin: 0.5rem !important;
    }
}

.pricing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.toogle-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.price-container {
    flex-wrap: wrap;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.pricing-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 6rem;
    padding-left: 6rem;
    border-radius: 8px;
}

.pricing-card span {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    color: #171516;
}

.features {
    display: flex;
    align-items: center;
    padding-top: 0.8rem;
}

.features p {
    font-family: Nunito Sans;
    font-style: normal;
    font-size: 14px;
    color: #000000;
    margin: 0;
}

.features img {
    margin-right: 12px;
}

.percent-box {
    position: absolute;
    width: 42px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(51, 94, 234, 0.6);
    border-radius: 2px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    right: -42px;
}

/*toogle*/

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #335eea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #335eea;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
}

/* .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
    -moz-box-shadow: 0px 0px 3px 2px #0099e0;
    box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
    -moz-box-shadow: 0px 0px 5px 5px #0099e0;
    box-shadow: 0px 0px 5px 5px #0099e0;
} */
