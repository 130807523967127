.headbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1b1b1b;
    padding-right: 12%;
    padding-left: 12%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.btn-head {
    /* opacity: 0.3; */
    width: 131px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.812308px;
    background-color: #1b1b1b;
    color: #ffffff;
    cursor: pointer;
    margin-left: 3rem;
}

.btn-head:hover {
    border: 1px solid rgba(255, 255, 255);
}




.btn-head-white {
    width: 131px;
    height: 40px;
    border: 1px solid grey;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.812308px;
    background-color: transparent;
    color: black;
    cursor: pointer;
    margin-left: 3rem;
}

.btn-head-white:hover {
    border: 1px solid black;
}

.home-btn {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.812308px;
    color: #1b1b1b;
    cursor: pointer;
}

.home-btn:hover {
    text-decoration: underline;
}
