.custom-input-white {
    margin-top: 3rem;
    /* justify-content: center; */
}

.custom-input-white input {
    border: none;
    border-bottom: solid 1px white;
    background-color: transparent;
    padding: 1rem;
    width: 309px;
    margin-right: 2rem;
    cursor: pointer;
    /* width: 44%; */
}

.custom-input-white input::placeholder {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    opacity: 0.8;
}

.custom-input-white button {
    cursor: pointer;
    background-color: white;
    border-radius: 3px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.812308px;
    color: #333a58;
    border: none;
    width: 185px;
    height: 50px;
}


.custom-input-black {
    margin-top: 3rem;
    justify-content: center;
}

.custom-input-black input {
    border: none;
    border-bottom: solid 1px black;
    background-color: transparent;
    padding: 1rem;
    width: 309px;
    margin-right: 2rem;
    /* width: 44%; */
}

.custom-input-black input::placeholder {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: black;
    opacity: 0.8;
}

.custom-input-black button {
    cursor: pointer;
    background-color: black;
    border-radius: 3px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.812308px;
    color: white;
    border: none;
    width: 185px;
    height: 50px;
}
